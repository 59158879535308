/* eslint-disable no-console */

import { register } from 'register-service-worker'
register(`${process.env.BASE_URL}service-worker.js`, {
  ready () {
    console.log(
      'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
    )
  },
  registered () {
  },
  cached () {
  },
  updatefound () {
    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName)
      })
    })
  },
  updated () {
    /*    caches.keys().then(cacheNames => {
      cacheNames.forEach(cacheName => {
        caches.delete(cacheName)
      })
    }) */
  },
  offline () {
  },
  error (error) {
    console.error('Error during service worker registration:', error)
  }
})
