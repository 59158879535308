export default {
  IdNumber: 'id number',
  password: 'password',
  phoneNumber: 'phone number',
  userName: 'user name',
  fullName: 'full name',
  email: 'email address',
  otbCode: 'code',
  check: 'check',
  enter: 'enter',
  createNewAccount: 'create new account',
  createAccount: 'create an account',
  codeOtb: 'otb code',
  otbTitle: 'A message has been sent to your mobile number',
  otbInfo: 'contain otb code',
  continueOtb: 'continue',
  create: 'create',
  enterLogin: 'login'
}
