import firebase from 'firebase'

const firebaseConfig = {
  apiKey: 'AIzaSyCOjq1ZfAuB3sF9bA5M7i3fbKA0oJxTSBg',
  appId: '1:846950817960:web:d4acbd5056630d8c09a29d',
  authDomain: 'ibbil-6e726.firebaseapp.com',
  measurementId: 'G-MCBGKVMLEL',
  messagingSenderId: '846950817960',
  projectId: 'ibbil-6e726',
  storageBucket: 'ibbil-6e726.appspot.com'
}
firebase.initializeApp(firebaseConfig)
