/* eslint-disable */ 
export default {
  storeType: 'نوع المتجر',
  storeNameInArabic: 'اسم المتجر بالعربية',
  storeNameInEnglish: 'اسم المتجر بالإنجليزية',
  theCity: 'المدينة',
  storeAddress: 'عنوان المتجر',
  TypeOfServiceProvided:'نوع الخدمة المقدمة',
  StoreType:'نوع المتجر'

}
