export default {
  products: 'المنتجات',
  mainImage: 'الصوره الرئيسية',
  name: 'الاسم',
  images: 'الصور',
  price: 'السعر',
  quantity: 'الكمية',
  date: 'التاريخ',
  status: 'الحالة',
  control: 'التحكم',
  addProduct: ' إضافة منتج',
  mainInfo: ' معلومات أساسية',
  productDetails: 'تفاصيل المنتج',
  transportation: ' الشحن',
  productAttributes: 'سمات المنتج',
  addAttributes: 'إضافة سمة جديدة',
  productRelated: '  منتجات ذات صلة',
  mainQuestions: 'الاسئلة الشائعة الخاصة بالمنتج',
  addQuestions: 'إضافة سؤال',
  searchWords: ' كلمات دالة',
  savePublish: 'حفظ و نشر',
  save: 'حفظ',
  publish: 'النشر',
  categories: 'الأقسام',
  groups: 'المجموعات',
  taxes: 'الضريبة'
}
