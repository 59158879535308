/* eslint-disable*/ 
export default {
  home: 'الرئيسية',
  aboutUs: 'من نحن',
  services: 'خدماتنا',
  mediaCenter: 'المركز الإعلامى',
  contactUs: 'تواصل',
  searchInAllDepartments: 'بحث فى جميع الأقسام',
  storeType: 'Store type',
  storeNameInArabic: 'Store Name In Arabic',
  storeNameInEnglish:'Store Name In English',
  theCity:'the City',
  storeAddress: 'Store Address',
  TypeOfServiceProvided:'Type of service provided',
  StoreType:'Store type'
}
