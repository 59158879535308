export default {
  methods: {
    hasPer: (nameOfRole) => {
      if (localStorage.getItem('my-roles')) {
        return JSON.parse(localStorage.getItem('my-roles')).indexOf(nameOfRole) > -1
      } else {
        return false
      }
    },
    isType: () => true,
    isAdmin: () => true
  }
}
