<template>
  <b-form-group :label="label" :label-for="name">
    <validation-provider
      :name="name"
      :rules="validate"
      :bails="false"
      #default="{ errors }"
    >
      <select
        :id="id"
        :disabled="disabled"
        :class="['form-control bg-white', { 'is-invalid': errors.length > 0 }]"
        :value="value"
        @change="$emit('input', $event.target.value)"
      >
        <option disabled value="">{{ placeholder }}</option>
        <option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      default: '',
      type: String
    },
    name: {
      default: '',
      type: String
    },
    id: {
      default: '',
      type: String
    },
    validate: {
      default: '',
      required: false
    },
    placeholder: {
      default: 'Select an option'
    },
    disabled: {
      default: false
    },
    options: {
      type: Array,
      default: () => []
    }
  }
}
</script>
