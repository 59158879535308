export default {
  products: 'products',
  mainImage: 'main image',
  name: 'name',
  images: 'images',
  price: 'price',
  quantity: 'quantity',
  date: 'date',
  status: 'status',
  control: 'controls',
  addProduct: ' add product',
  mainInfo: ' main information',
  productDetails: 'product details',
  transportation: ' transportation',
  productAttributes: 'product attributes',
  addAttributes: 'add new attributes',
  productRelated: ' products related',
  mainQuestions: 'common questions for products',
  addQuestions: 'add question',
  searchWords: 'search words',
  savePublish: 'save and publish',
  save: 'save',
  publish: 'publish',
  categories: 'categories',
  groups: 'groups',
  taxes: 'taxes'
}
