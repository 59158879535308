import axios from 'axios'
import { core } from '@/config/pluginInit'
import { VUE_APP_MARKETPLACE_LINK, VUE_APP_SSO_LINK, VUE_APP_WEBSITE_LINK } from './config/constant'
export function singleSignOn () {
  const apiClient = axios.create({
    baseURL: VUE_APP_SSO_LINK,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('userToken') ? JSON.parse(localStorage.getItem('userToken')) : null}`,
      Accept: 'application/json',
      ContentType: 'application/json',
      'Accept-Language': 'ar',
      'Content-Language': 'ar'
    }
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        core.showSnackbar('error', value)
      }
    } else if (error.response.data) {
      core.showSnackbar('error', error.response.data.message)
    }
    return Promise.reject(error)
  })
  return apiClient
}
export function marketPlace () {
  const apiClient = axios.create({
    baseURL: VUE_APP_MARKETPLACE_LINK,
    headers: {
      Authorization: localStorage.getItem('userToken') ? `Bearer ${JSON.parse(localStorage.getItem('userToken'))}` : null,
      Accept: 'application/json',
      ContentType: 'application/json',
      'Accept-Language': 'ar',
      'Content-Language': 'ar'
    }
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response.data) {
      core.showSnackbar('error', error.response.data.message)
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        core.showSnackbar('error', value)
      }
    }
    return Promise.reject(error)
  })
  return apiClient
}
export function website () {
  const apiClient = axios.create({
    baseURL: VUE_APP_WEBSITE_LINK,
    headers: {
      Authorization: localStorage.getItem('userToken') ? `Bearer ${JSON.parse(localStorage.getItem('userToken'))}` : null,
      Accept: 'application/json',
      ContentType: 'application/json',
      'Accept-Language': 'ar',
      'Content-Language': 'ar'
    }
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response.data) {
      core.showSnackbar('error', error.response.data.message)
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        core.showSnackbar('error', value)
      }
    }
    return Promise.reject(error)
  })
  return apiClient
}
