import admins from './admins/admins'
import main from './main/main'
import products from './products/products'
import auth from './auth/auth'
export default {
  admins,
  main,
  auth,
  products
}
